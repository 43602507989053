define("discourse/plugins/discourse-templates/discourse/templates/connectors/topic-above-posts/discourse-templates-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="template-topic-controls">
    <DButton
      @icon={{if this.copyConfirm "check" "copy"}}
      @action={{this.copy}}
      @label="templates.copy"
      class={{concat-class
        "btn-default"
        "template-copy"
        (if this.copyConfirm "ok")
      }}
    />
    <DButton
      @action={{this.createNewTopic}}
      @label="templates.new_topic"
      @icon="plus"
      class="template-new-topic"
    />
  </div>
  */
  {
    "id": "JxzWUf6/",
    "block": "[[[10,0],[14,0,\"template-topic-controls\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,0,[28,[37,1],[\"btn-default\",\"template-copy\",[52,[30,0,[\"copyConfirm\"]],\"ok\"]],null]]],[[\"@icon\",\"@action\",\"@label\"],[[52,[30,0,[\"copyConfirm\"]],\"check\",\"copy\"],[30,0,[\"copy\"]],\"templates.copy\"]],null],[1,\"\\n  \"],[8,[39,0],[[24,0,\"template-new-topic\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"createNewTopic\"]],\"templates.new_topic\",\"plus\"]],null],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"concat-class\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/templates/connectors/topic-above-posts/discourse-templates-button.hbs",
    "isStrictMode": false
  });
});