define("discourse/plugins/discourse-templates/discourse/connectors/below-footer/d-templates-modal-container", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-templates/discourse/components/d-templates/modal/form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _form, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DTemplatesModalContainer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dTemplatesModal", [_service.inject]))();
    #dTemplatesModal = (() => (dt7948.i(this, "dTemplatesModal"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.dTemplatesModal.model}}
          <div class="modal-container d-templates-modal-container">
            <DTemplatesModalForm
              @closeModal={{this.dTemplatesModal.hide}}
              @model={{this.dTemplatesModal.model}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "GkXcxQtT",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"dTemplatesModal\",\"model\"]],[[[1,\"      \"],[10,0],[14,0,\"modal-container d-templates-modal-container\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@closeModal\",\"@model\"],[[30,0,[\"dTemplatesModal\",\"hide\"]],[30,0,[\"dTemplatesModal\",\"model\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-templates/discourse/connectors/below-footer/d-templates-modal-container.js",
      "scope": () => [_form.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DTemplatesModalContainer;
});