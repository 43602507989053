define("discourse/plugins/discourse-templates/discourse/components/d-templates/item", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <details class="template-item" id="template-item-{{@template.id}}">
    <summary class="template-item-title">
      <div class="template-item-title-text">{{@template.title}}</div>
  
      <div class="actions">
        <DButton
          class="templates-apply"
          @action={{action "apply"}}
          @icon="far-clipboard"
        />
      </div>
    </summary>
  
    <DTemplates::ItemContent @template={{@template}} />
  </details>
  */
  {
    "id": "6/xlvlVX",
    "block": "[[[10,\"details\"],[14,0,\"template-item\"],[15,1,[29,[\"template-item-\",[30,1,[\"id\"]]]]],[12],[1,\"\\n  \"],[10,\"summary\"],[14,0,\"template-item-title\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"template-item-title-text\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"templates-apply\"]],[[\"@action\",\"@icon\"],[[28,[37,1],[[30,0],\"apply\"],null],\"far-clipboard\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@template\"],[[30,1]]],null],[1,\"\\n\"],[13]],[\"@template\"],false,[\"d-button\",\"action\",\"d-templates/item-content\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/components/d-templates/item.hbs",
    "isStrictMode": false
  });
  class DTemplatesItem extends _component2.default {
    apply() {
      // run parametrized action to insert the template
      this.args.onInsertTemplate?.(this.args.template);
      (0, _ajax.ajax)(`/discourse_templates/${this.args.template.id}/use`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_ = (() => dt7948.n(this.prototype, "apply", [_object.action]))();
  }
  _exports.default = DTemplatesItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DTemplatesItem);
});