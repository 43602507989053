define("discourse/plugins/discourse-templates/discourse/components/d-templates/modal/form", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{i18n "templates.insert_template"}}
    class="d-templates d-templates-modal"
  >
    <:body>
      <DTemplates::FilterableList
        @textarea={{@model.textarea}}
        @onInsertTemplate={{@model.onInsertTemplate}}
        @onAfterInsertTemplate={{@closeModal}}
      />
    </:body>
  </DModal>
  */
  {
    "id": "StqqkQ0S",
    "block": "[[[8,[39,0],[[24,0,\"d-templates d-templates-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"templates.insert_template\"],null]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@textarea\",\"@onInsertTemplate\",\"@onAfterInsertTemplate\"],[[30,2,[\"textarea\"]],[30,2,[\"onInsertTemplate\"]],[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"d-templates/filterable-list\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/components/d-templates/modal/form.hbs",
    "isStrictMode": false
  });
  class Form extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    constructor() {
      super(...arguments);
      if (this.args.closeModal) {
        this.appEvents.on("page:changed", this, this.args.closeModal);
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.args.closeModal) {
        this.appEvents.off("page:changed", this, this.args.closeModal);
      }
    }
  }
  _exports.default = Form;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Form);
});